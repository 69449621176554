import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// TODO: depending how big the final bundle is we could extract this to a backend approach...
import resources from "./i18n.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    resources,
    lng: "en",
    debug: process.env.NODE_ENV === "development",
    nsSeparator: ":",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
