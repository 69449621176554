import React from "react";
import styles from "./Loading.module.scss";
import { Image } from "../Components/Image/Image";
import LoadingIcon from "../assets/loading.svg";
import { Spinner } from "../Components/Spinner/Spinner";
import { Banner } from "../Components/Banner/Banner";
import { Footer } from "../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation("loading"); 
  return (
    <div className={styles.loading}>
      <Banner />
      <div className={styles.content}>
        <div className={styles.icon}>
          <Image src={LoadingIcon} alt="loading" height={100} width={100} />
        </div>
        <div className={styles.title}>{t("pleaseWait")}</div>
        <div className={styles.text}>
          <p>{t("authorising")}</p>
        </div>
        <div className={styles.spinner}>
          <Spinner size="s" type="light" delay={1000} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { Loading };
