import React from "react";
import styles from "./Welcome.module.scss";
import { useGlobalContext } from "../../Contexts/GlobalState";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const ctx = useGlobalContext();
  const { t } = useTranslation();

  return (
    <div className={styles.welcome}>
      <div className={styles.left} />
      <div className={styles.content}>
        <div className={styles.lead}>
          <h2>
            {t("dashboard:welcome")}, {ctx.user?.profile.given_name ?? "User"}
          </h2>
        </div>
        <div className={styles.pleaseSelect}>
          <span>{t("dashboard:selectSoftware")}</span>
        </div>
      </div>
      <div className={styles.right} />
    </div>
  );
};

export { Welcome };
