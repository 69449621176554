import React from "react";
import styles from "./Error.module.scss";
import { Image } from "../Components/Image/Image";
import { Banner } from "../Components/Banner/Banner";
import { Footer } from "../Components/Footer/Footer";
import ErrorIcon from "../assets/error.svg";
import { useTranslation } from "react-i18next";

type ErrorProps = {
  message: string;
};

const Error = ({ message }: ErrorProps) => {
  const { t } = useTranslation("error");
  return (
    <div className={styles.error}>
      <Banner />
      <div className={styles.content}>
        <div className={styles.inner}>
          <div className={styles.icon}>
            <Image src={ErrorIcon} alt="error" height={100} width={100} />
          </div>
          <div className={styles.title}>{t("houston")}</div>
          <div className={styles.text}>
            <div>{message}</div>
            <div>{t("pleaseRefresh")}</div>
          </div>
          <div className={styles.contact}>
            <div>{t("stillIssues")}</div>
            <div>
              <a href={`mailto:${t("supportEmail")}`}>{t("supportEmail")}</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { Error as ErrorFeature };
