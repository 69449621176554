import React from "react";
import { Button } from "../Button/Button";
import styles from "./Card.module.scss";
import { Image } from "../Image/Image";
import { useTranslation } from "react-i18next";

export type CardProps = {
  backgroundColor: string;
  textColour: string;
  headerImage: string;
  title: string;
  text: string;
  url: string;
  imageHeight?: number;
  imageWidth?: number;
};

const Card = ({
  title,
  headerImage,
  text,
  backgroundColor,
  textColour,
  url,
  imageHeight,
  imageWidth,
}: CardProps) => {
  const { t } = useTranslation();

  return (
    <a href={url} target="_blank" rel="noreferrer" data-custom-target_url={url}>
      <div
        className={styles.card}
        style={{ backgroundColor: `${backgroundColor}` }}
      >
        <div className={styles.header}>
          <Image
            alt={t("images:cardAlt")}
            src={headerImage}
            height={imageHeight}
            width={imageWidth}
          />
        </div>
        <div className={styles.content}>
          <div
            className={styles.title}
            style={{ color: `${textColour ? textColour : "#fffff"}` }}
          >
            <h2>{title}</h2>
          </div>
          <div className={styles.text}>
            <p>{text}</p>
          </div>
        </div>
        <div className={styles.button}>
          <Button
            text={t("dashboard:launch")}
            backgroundColour="white"
            textColour={backgroundColor}
            onClick={() => {
              return;
            }}
          />
        </div>
      </div>
    </a>
  );
};

export { Card };
