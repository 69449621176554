import { useMemo, useState, useEffect } from "react";
import { UserManager, User } from "oidc-client";

type Props = {
  userManager: UserManager;
};

const useUserManager = ({ userManager }: Props) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [signInRequired, setSignInRequired] = useState(true);
  const [silentRenewError, setSilentRenewError] = useState(false);

  useMemo(() => {
    userManager.events.addUserLoaded((user) => {
      if (!user.expired) {
        setUser(user);
        setSignInRequired(false);
      }
    });

    userManager.events.addUserUnloaded(() => {
      setUser(undefined);
      setSignInRequired(true);
    });

    userManager.events.addAccessTokenExpired(() => {
      setUser(undefined);
      setSignInRequired(true);
    });

    userManager.events.addSilentRenewError(() => {
      setUser(undefined);
      setSignInRequired(true);
      setSilentRenewError(true);
    });
  }, [userManager]);

  useEffect(() => {
    userManager.getUser().then((user) => {
      if (!user?.expired ?? false) {
        setUser(user!);
      }
    });
  }, [userManager]);

  return [user, signInRequired, silentRenewError] as const;
};

export { useUserManager };
