import React from "react";
import styles from "./Button.module.scss";

export type ButtonProps = {
  text: string;
  backgroundColour: string;
  textColour: string;
  onClick: () => void;
};

const Button = ({
  text,
  backgroundColour,
  textColour,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={styles.button}
      style={{ backgroundColor: `${backgroundColour}`, color: `${textColour}` }}
      onClick={() => onClick()}
    >
      {text}
    </button>
  );
};

export { Button };
