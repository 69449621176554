import React, { useState } from 'react';
import styles from './Spinner.module.scss';
import { useInterval } from '../../Utils/IntervalHook';

type Size = 'xs' | 's' | 'm' | 'l';

type SpinnerProps = {
	type?: 'light' | 'dark';
	size?: Size;
	delay?: number;
	primaryText?: string;
	secondaryText?: string;
	textDelay?: number;
	overlay?: boolean;
};

const Spinner = ({
	type = 'light',
	size = 'l',
	delay = 1000,
	textDelay = 3000,
	primaryText,
	secondaryText,
	overlay,
}: SpinnerProps) => {
	const [showSpinner, setShowSpinner] = useState<boolean>(delay === undefined);
	const [showText, setShowText] = useState<boolean>(textDelay === undefined);

	useInterval(() => setShowSpinner(true), delay);
	useInterval(() => setShowText(true), textDelay);

	const spinnerStyle =
		type === 'light' ? styles.lightSpinner : styles.darkSpinner;

	const spinnerSize = () => {
		switch (size) {
			case 'xs':
				return styles.xs;
			case 's':
				return styles.s;
			case 'm':
				return styles.m;
			default:
				return styles.l;
		}
	};

	const spinner = (
		<div data-testid="spinner-container">
			<div className={`${spinnerStyle} ${spinnerSize()}`}></div>
			<div
				className={`${
					showText && (primaryText || secondaryText) ? styles.textShown : ''
				}`}
			>
				<div className={styles.primaryText}>{primaryText}</div>
				<div className={styles.secondaryText}>{secondaryText}</div>
			</div>
		</div>
	);

	if (showSpinner) {
		return (
			<>
				{overlay ? (
					<div className={styles.spinnerOverlay}>{spinner}</div>
				) : (
					<>{spinner}</>
				)}
			</>
		);
	} else return <></>;
};

export { Spinner };
