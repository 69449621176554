import React from "react";
import { SignIn } from "./Authentication/SignIn";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { OidcCallback } from "./Authentication/OidcCallback";
import { Dashboard } from "./Features/Dashboard";
import { SilentRenew } from "./Authentication/SilentRenew";
import { Authenticator } from "./Authentication/Authenticator";

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/oidc-callback" element={<OidcCallback />} />
      <Route path="/silent-renew" element={<SilentRenew />} />
      <Route
        path="/*"
        element={
          <Authenticator>
            <Dashboard />
          </Authenticator>
        }
      />
    </Routes>
  </Router>
);

export { AppRoutes };
