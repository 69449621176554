import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { Image } from "../Image/Image";
import JinGraphics from "../../assets/jingraphics.svg";
import AssetLogo from "../../assets/asset55-logo.svg";
import DeepLead from "../../assets/deep-portal.svg";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [rendered, setRendered] = useState<boolean>(false); // Could be made a render hook
  const { t } = useTranslation();

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <div className={`${styles.footer} ${rendered ? styles.show : ""}`}>
      <div className={styles.lead}>
        <Image src={DeepLead} alt={t("footer:poweredBy")} height={30} />
      </div>
      <div className={styles.content}>
        <div className={styles.copyright}>
          <span>{t("footer:copyright", { year: new Date().getFullYear() })}</span>
        </div>
        <div className={styles.logos}>
          <div className={styles.badge}>
            <Image
              src={JinGraphics}
              alt={t("images:jgsAlt")}
              height={isMobile ? 35 : 55}
            />
          </div>
          <div className={styles.asset}>
            <Image
              src={AssetLogo}
              alt={t("images:assetAlt")}
              height={isMobile ? 50 : 75}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
