import { Button, ButtonProps } from "../Button/Button";
import React, { ReactNode } from "react";
import styles from "./Modal.module.scss";
import { CSSTransition } from "react-transition-group";

export type ModalOptions = {
  isOpen: boolean;
  content: React.ReactNode;
  title?: string;
};

const emptyModal: ModalOptions = {
  content: <></>,
  isOpen: false,
};

type Props = {
  options: ModalOptions;
};

const Modal = ({ options }: Props) => {
  return (
    <>
      <div
        className={`${styles.modalOverlay} ${
          options.isOpen ? styles.isOpen : ""
        }`}
      />
      <CSSTransition
        key="modal"
        in={options.isOpen}
        timeout={0}
        classNames={{
          enterActive: styles.modalEnterActive,
          enterDone: styles.modalEnterDone,
          exitActive: styles.modalExitActive,
          exitDone: styles.modalExitDone,
        }}
      >
        <div data-testid="modal" className={styles.modal}>
          {options.title && (
            <div className={styles.modalHeader}>{options.title}</div>
          )}
          <div className={styles.modalBody}>{options.content}</div>
        </div>
      </CSSTransition>
    </>
  );
};

type ModalContentProps = {
  children: ReactNode;
};

const ModalContent = ({ children }: ModalContentProps) => {
  return <div className={styles.innerContainer}>{children}</div>;
};

type ModalActionsProps = {
  actions: ButtonProps
};

const ModalActions = ({ actions }: ModalActionsProps) => {
  return (
    <div className={styles.modalActions}>
        <Button {...actions} />
    </div>
  );
};

export { Modal, ModalContent, ModalActions, emptyModal };
