import React, { ReactNode, useEffect, useState } from "react";
import { useGlobalContext } from "../Contexts/GlobalState";
import { useNavigate, useLocation } from "react-router-dom";
import { Loading } from "../Features/Loading";
import { ErrorFeature } from "../Features/Error";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
};

export const isEmpty = (value: any): boolean =>
  value == null || value === undefined;

const Authenticator = ({ children }: Props) => {
  const context = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [signIn, setSignIn] = useState<boolean>(false);

  const { t } = useTranslation("error");

  useEffect(() => {
    if (signIn) {
      setSignIn(false);
      navigate("/signin", {
        state: { destination: `${location.pathname}${location.search}` },
      });
    }
  }, [signIn, navigate, location.pathname, location.search]);

  if (context.signInError) {
    return <ErrorFeature message={t("signinProblem")} />;
  } else {
    if (isEmpty(context.user) && !signIn) {
      setSignIn(true);
      return <Loading />;
    } else {
      return <>{children} </>;
    }
  }
};

export { Authenticator };
