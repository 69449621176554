import React, { useEffect } from "react";
import { useGlobalContext } from "../Contexts/GlobalState";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Features/Loading";
import { User } from "oidc-client";
import { setAuthenticatedUserContext } from "../App";
import { ErrorFeature } from "../Features/Error";
import { useAppInsightsError } from "../Utils/AppInsightsHook";
import { useTranslation } from "react-i18next";

const OidcCallback = () => {
  const context = useGlobalContext();
  const navigate = useNavigate();

  const { raiseAppInsightsError } = useAppInsightsError();

  const { t } = useTranslation("error");

  useEffect(() => {
    const signinCallBack = async () => {
      try {
        if (!context.user?.access_token || context.user.expired) {
          await context.userManager
            .signinRedirectCallback()
            .then((user: User) => {
              context.userManager.storeUser(user);
              context.setSignInInProgress(false);
              setAuthenticatedUserContext(user?.profile.sub);
            });
        }

        navigate("/");
      } catch (error: any) {
        raiseAppInsightsError("OidcCallback - signinRedirectCallback", error);
        context.userManager.removeUser();
        context.setSignInInProgress(false);
        context.setSignInError(true);
      }
    };

    signinCallBack();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !context.signInInProgress && context.signInError ? (
    <ErrorFeature message={t("oidcProblem")} />
  ) : (
    <Loading />
  );
};

export { OidcCallback };
