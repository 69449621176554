import { useRef, useEffect, useState } from "react";

// This is taken from a blog: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: any, delay: number | undefined) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== undefined) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**A wrapper around the useInterval. Used to show delayed spinners/loading components */
export const useLoadingDelay = (
  isLoading: boolean,
  delay: number | undefined
) => {
  const [shouldShowLoadingComponent, setShouldShowLoadingComponent] =
    useState<boolean>(false);

  useInterval(() => setShouldShowLoadingComponent(isLoading), delay);

  useEffect(() => {
    if (!delay) {
      setShouldShowLoadingComponent(false);
    }
  }, [delay]);

  return shouldShowLoadingComponent;
};
