import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export const useAppInsightsError = () => {
  const appInsights = useAppInsightsContext();

  const raiseAppInsightsError = (message: string, error: Error) => {
    appInsights.trackException(
      {
        exception: error as Error,
        severityLevel: SeverityLevel.Error,
      },
      {
        message: "Error with SignIn hook",
        customerError: error,
      }
    );
  };

  return { raiseAppInsightsError };
};
