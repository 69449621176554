// create context with no upfront defaultValue

import React from 'react';

// without having to do undefined check all the time
export function createCtx<A>() {
	const ctx = React.createContext<A | undefined>(undefined);
	function useCtx() {
		const c = React.useContext(ctx);
		if (!c) throw new Error('useCtx must be inside a Provider with a value');
		return c;
	}
	return [useCtx, ctx.Provider] as const;
}
