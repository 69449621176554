import React from "react";
import styles from "./Banner.module.scss";
import { Image } from "../Image/Image";
import HCCPLogo from "../../assets/hccp-logo.svg";
import UserBadge from "../../assets/user.svg";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation("images");
  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <div className={styles.user}>
          <Image
            src={UserBadge}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_DATA_AUTH_AUTHORITY}/account`
              )
            }
            height={50}
            width={50}
            alt={t("userAlt")}
          />
        </div>
        <div className={styles.pad} />
        <div className={styles.image}>
          <Image src={HCCPLogo} alt={t("hccpAlt")} />
        </div>
      </div>
    </div>
  );
};

export { Banner };
