import React from "react";
import { useGlobalContext } from "../Contexts/GlobalState";
import { Loading } from "../Features/Loading";

const SilentRenew = () => {
  const context = useGlobalContext();
  context.userManager.signinSilentCallback();
  return <Loading />;
};

export { SilentRenew };
