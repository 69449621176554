import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import { Card, CardProps } from "../Components/Card/Card";
import { Banner } from "../Components/Banner/Banner";
import { Footer } from "../Components/Footer/Footer";
import JSLogo from "../assets/js-logo.svg";
import ExecuteLogo from "../assets/execute-logo.svg";
import { Welcome } from "../Components/Welcome/Welcome";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../Contexts/GlobalState";
import { ModalActions, ModalContent } from "../Components/Modal/Modal";
import { DataNotice } from "../Components/DataNotice/DataNotice";

const Dashboard = () => {
  const { t } = useTranslation();
  const ctx = useGlobalContext();

  const [rendered, setRendered] = useState<boolean>(false);

  useEffect(() => {
    const noticeShown = localStorage.getItem("DEEP_Notice_Shown");

    if (!noticeShown && ctx.user?.profile) {
      ctx.showModal({
        title: "Data Policy",
        content: (
          <>
            <ModalContent>
              <DataNotice />
            </ModalContent>
            <ModalActions
              actions={{
                text: "Ok",
                backgroundColour: "#72bbdc",
                textColour: "white",
                onClick: () => ctx.hideModal(),
              }}
            />
          </>
        ),
        isOpen: true,
      });
      localStorage.setItem("DEEP_Notice_Shown", "true");
    }
    setRendered(true);
  }, [ctx]);

  const cards: Array<CardProps> = [
    {
      headerImage: ExecuteLogo,
      title: t("companies:execute"),
      text: t("companies:csLeadText"),
      backgroundColor: "#3D3D3D",
      textColour: "#ffff",
      url: `${process.env.REACT_APP_DATA_CS_URL}`,
      imageHeight: 120,
      imageWidth: 120,
    },
    {
      headerImage: JSLogo,
      title: t("companies:jinSolution"),
      text: t("companies:jsLeadText"),
      backgroundColor: "#000000",
      textColour: "#72bbdc",
      url: `${process.env.REACT_APP_DATA_JIN_URL}`,
      imageHeight: 100,
      imageWidth: 200,
    },
  ];

  return (
    <div
      data-testid="dashboard"
      className={`${styles.dashboard} ${rendered ? styles.show : ""}`}
    >
      <Banner />
      <div className={styles.content}>
        <Welcome />
        <div className={styles.cards}>
          <div className={styles.left} />
          <div className={styles.list}>
            {cards.map((card: CardProps, i: number) => (
              <Card key={i} {...card} />
            ))}
          </div>
          <div className={styles.right} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { Dashboard };
