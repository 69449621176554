import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../Contexts/GlobalState";
import { User } from "oidc-client";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Features/Loading";
import { ErrorFeature } from "../Features/Error";
import { setAuthenticatedUserContext } from "../App";
import { useAppInsightsError } from "../Utils/AppInsightsHook";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const context = useGlobalContext();
  const navigate = useNavigate();
  const { raiseAppInsightsError } = useAppInsightsError();
  const { t } = useTranslation("error");
  const [error, setError] = useState<string>();

  useEffect(() => {
    const signIn = async () => {
      context.userManager
        .getUser()
        .then(async (user: User | null) => {
          if (user?.expired ?? true) {
            context.userManager.signinRedirect().catch((error) => {
              raiseAppInsightsError("SignIn - signinRedirect error", error);
              context.setSignInError(true);
              setError(t("signinRedirectProblem"));
            });
          } else {
            try {
              setAuthenticatedUserContext(user?.profile.sub);
              navigate("/");
            } catch (error: any) {
              raiseAppInsightsError(
                "SignIn - setAuthenticatedUserContext error",
                error
              );
              context.setSignInError(true);
              setError(t("signinSetUserProblem"));
            }
          }
        })
        .catch((error) => {
          raiseAppInsightsError("SignIn - getUser error", error);
          context.setSignInError(true);
          setError(t("signinGetUserProblem"));
        });
    };

    signIn();
  }, [context.userManager]); // eslint-disable-line react-hooks/exhaustive-deps

  return context.signInError ? (
    <ErrorFeature message={error ?? ""} />
  ) : (
    <Loading />
  );
};

export { SignIn };
